import React from "react";
import { getStorage } from "../../modals/Storage";
import styles from "../ImportantNotice/Notice.module.css";

const ImportantNotice = () => {
  return (
    <div className={[styles.noticeView]}>
      <div className={[styles.noticeIconView]}>
    <img style={{width:"29px"}}
    src="https://bigluck99.in/static/media/notice_icon.1be5a58ebb09a0b25c81fef83d4d6f4c.svg"
    />
      </div>

      <div className={[styles.noticeBox]}>{getStorage("impMessage")}</div>
    </div>
  );
};

export default ImportantNotice;
