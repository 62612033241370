import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { getStorage, setStorage } from "../modals/Storage";
import { API_ACCESS_URL, copyText } from "../modals/Constants";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Invite() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const toastDialogRef = useRef(null);

  const [pageConst, setConstants] = useState({
    pageTitle: "Promotion",
    totalRegisteredDs: 0,
    totalDepositNumberDs: 0,
    totalDepositAmountDs: 0,
    totalFirstDepositNumberDs: 0,

    totalRegisteredTs: 0,
    totalDepositNumberTs: 0,
    totalDepositAmountTs: 0,
    totalFirstDepositNumberTs: 0,
    totalTodaysIncome: 0,
    recordList: [],
  });

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateRecordList = (data) => {
    let tempData = [];
    let dataList = data.data;

    // for (let i = 0; i < dataList.length; i++) {
    //   tempData.push(
    //     <div key={i} className="row-view sb-view w-100 mg-t-10">
    //       <div className="h-w-36 br-50 v-center cl-white bg-drk-blue">
    //         {dataList[i]["t_msg"] == "Level 2"
    //           ? "Lv2"
    //           : dataList[i]["t_msg"] == "Level 2"
    //             ? "Lv2"
    //             : "Lv1"}
    //       </div>

    //       <div className="row-view sb-view w-85">
    //         <div className="col-view">
    //           <span className="cl-drk-black ft-sz-17">
    //             {dataList[i]["t_msg"] != "Recharge Bonus"
    //               ? dataList[i]["t_msg"] + "- Commission"
    //               : dataList[i]["t_msg"]}
    //           </span>
    //           <div className="row-view ft-sz-12 cl-l-grey mg-t-5">
    //             <span>
    //               {dataList[i]["t_date"] + " " + dataList[i]["t_time"]}
    //             </span>
    //             <span className="mg-l-15">
    //               from {dataList[i]["t_receive_from"]}
    //             </span>
    //           </div>
    //         </div>

    //         <span className="cl-drk-black">+₹{dataList[i]["t_amount"]}</span>
    //       </div>
    //     </div>
    //   );

    //   if (dataList.length - 1 > i) {
    //     tempData.push(
    //       <div key={i + 25} className="line-hv-grey mg-t-10"></div>
    //     );
    //   }
    // }

    setConstants({
      ...pageConst,
      totalRegisteredDs: data["total_registered_d_sub"],
      totalDepositNumberDs: data["total_deposit_number_d_sub"],
      totalDepositAmountDs: data["total_deposit_amount_d_sub"],
      totalFirstDepositNumberDs: data["total_num_firstdeposit_d_sub"],

      totalRegisteredTs: data["total_registered_t_sub"],
      totalDepositNumberTs: data["total_deposit_number_t_sub"],
      totalDepositAmountTs: data["total_deposit_amount_t_sub"],
      totalFirstDepositNumberTs: data["total_num_firstdeposit_t_sub"],
      totalTodaysIncome: data["total_todays_income"],
    });
    // setConstants((previousState) => {
    //   return { ...previousState, recordList: tempData };
    // });
  };

  function getCommissionRecords() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-commission-records",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          setStorage("cbalance", data.total_income, 30);
          updateRecordList(data);
        }
      } catch (error) {
        console.log(error);
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  const copyMyId = () => {
    copyText(getStorage("uid"));
    showToast("Invitation Code Copied!", 2500, 0, 1);
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      getCommissionRecords();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className="zoomAnimView">
          <div
            className={[
              general.viewCenter,
              general.pd10px,
              general.bgCustomColor4,
            ].join(" ")}
          >
            <span className={[general.colorBlack, general.fontS20Px].join(" ")}>
              {pageConst.pageTitle}
            </span>
          </div>

          <div
            className={[
              styles.promotionDetailView,
            ].join(" ")}
          >
            <div className="v-center">
              <span className="cl-white ft-sz-20">₹</span>
              <span className="cl-white ft-sz-20 mg-l-3">
                {pageConst.totalTodaysIncome}
              </span>
            </div>

            <span className="cl-red pd-5-15 ft-sz-14 br-10 bg-white mg-t-5">
              Yesterday's total income
            </span>
            <span className="cl-white ft-sz-12 mg-t-5">
              Upgrade the level to increase commission income
            </span>
          </div>

          <div className="pd-15 mg-t--130">
            <div className="w-100 row-view sb-view a-flex-start br-10 bg-white ovf-hidden">
              <div className="w-100 col-view">
                <div className="bg-red-2 w-100 row-view pd-10">
                  <img
                    className="h-w-22"
                    src={require("../media/icons/direct_subordinate_icon.png")}
                    alt="icon"
                  />
                  <span className="cl-white ft-sz-14 mg-l-10">Direct Subordinates</span>
                </div>

                <div className="w-100 col-view a-center pd-10">
                  <span className="cl-grey ft-sz-13">
                    {pageConst.totalRegisteredDs}
                  </span>
                  <span className="cl-grey ft-sz-12">number of register</span>

                  <span className="cl-green ft-sz-13 mg-t-10">
                    {pageConst.totalDepositNumberDs}
                  </span>
                  <span className="cl-grey ft-sz-12">Deposit number</span>

                  <span className="cl-golden ft-sz-13 mg-t-10">
                    {pageConst.totalDepositAmountDs}
                  </span>
                  <span className="cl-grey ft-sz-12">Deposit amount</span>

                  <span className="cl-grey ft-sz-13 mg-t-10">
                    {pageConst.totalFirstDepositNumberDs}
                  </span>
                  <span className="txt-a-center ft-sz-12">
                    Number of people making<br></br>first deposit
                  </span>
                </div>
              </div>

              <div className="w-100 col-view">
                <div className="bg-red-2 w-100 row-view pd-10">
                  <img
                    className="h-w-22"
                    src={require("../media/icons/team_subordinate_icon.png")}
                    alt="icon"
                  />
                  <span className="cl-white ft-sz-14 mg-l-10">Team Subordinates</span>
                </div>

                <div className="w-100 col-view a-center pd-10">
                  <span className="cl-grey ft-sz-14">
                    {pageConst.totalRegisteredTs}
                  </span>
                  <span className="cl-grey ft-sz-13">number of register</span>

                  <span className="cl-green ft-sz-14 mg-t-10">
                    {pageConst.totalDepositNumberTs}
                  </span>
                  <span className="cl-grey ft-sz-13">Deposit number</span>

                  <span className="cl-golden ft-sz-14 mg-t-10">
                    {pageConst.totalDepositAmountTs}
                  </span>
                  <span className="cl-grey ft-sz-13">Deposit amount</span>

                  <span className="cl-grey ft-sz-14 mg-t-10">
                    {pageConst.totalFirstDepositNumberTs}
                  </span>
                  <span className="txt-a-center ft-sz-13">
                    Number of people making<br></br>first deposit
                  </span>
                </div>
              </div>
            </div>

            <Link
              className={[
                general.textDecoNone,
                general.height45px,
                general.width100,
                general.viewCenter,
                general.colorWhite,
                general.mgT10px,
                general.fontS17Px,
                general.fontW500,
                general.letterSpace1p8px,
                general.borderRadius20px,
                general.bgCustomColor1,
              ].join(" ")}
              to={"/invitelink"}
            >
              INVITATION LINK
            </Link>

            <Link
              className="row-view cl-black pd-15 sb-view txt-deco-n br-10 bg-white mg-t-15"
              onClick={() => copyMyId()}
            >
              <div className="row-view">
                <img
                  src={require("../media/icons/invite_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-grey mg-l-10">Invitation Code:</span>
              </div>

              <span className="cl-grey">{getStorage("uid")}</span>
            </Link>

            <Link
              className="row-view cl-black pd-15 sb-view txt-deco-n br-10 bg-white mg-t-10"
              to={"/privilege"}
            >
              <div className="v-center">
                <img
                  src={require("../media/icons/invite_rule_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-grey mg-l-10">Invitation Rules</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#FFFFFF"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </Link>

            <Link
              className="row-view cl-black pd-15 sb-view txt-deco-n br-10 bg-white mg-t-10 mg-b-8em"
              to={"/allmembers"}
            >
              <div className="v-center">
                <img
                  src={require("../media/icons/subordinates_data_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-grey mg-l-10">Subordinates Data</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#FFFFFF"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </Link>
          </div>
        </div>
        <BottomNavbar activeBar="invite" />
      </div>
    </div>
  );
}

export default Invite;
