import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import {
  WEBSITE_NAME,
  redirectTo,
  generateReferalURL,
} from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import ShareOptionsView from "../other-components/ShareOptionsView";
import general from "../css/General.module.css";

function InviteLink() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);

  const [pageConst, setConstants] = useState({
    pageTitle: "Invitation Link",
  });

  const topBarClickAction = (data) => {};

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />

        <div className={[general.colView, general.mgB10px].join(" ")}>
          <div
            className={[
              general.viewCenter,
              general.colView,
              general.pd5px15px,
              general.mgT50px,
            ].join(" ")}
          >
            <span
              className={[
                general.colorGrey,
                general.fontSize18px,
                general.mgT10px,
              ].join(" ")}
            >
              My Invite Link
            </span>

            <div
              className={[
                general.viewCenter,
                general.width100,
                general.colorGrey,
                general.pd10px15px,
                general.fontSize20px,
                general.borderRadius,
                general.border1pxSolidLightWhite,
                general.mgT15px,
              ].join(" ")}
            >
              <span>{generateReferalURL(getStorage("uid"))}</span>
            </div>

            <ShareOptionsView updateState={showToast} />

            <span
              className={[
                general.colorGrey,
                general.fontSize13px,
                general.mgT10px,
              ].join(" ")}
            >
              {WEBSITE_NAME} rules and regulations prohibit multiple accounts.
              You may be blocked if you use multiple accounts or conduct
              suspicious activities.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteLink;
